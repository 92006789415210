import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import title_image from '../../images/titles/digital-strategy.jpg'


const pageTitle = "Digital Strategy";
const pageSlug = "digital-strategy";


class DigitalStrategy extends React.Component{
    render(){
      return(
        <Layout>

<section className="section page-title" style={{ backgroundImage: `url(${title_image})`}}>
      <div className="overlay"></div>
        <div className="container">
            <h1 className="title">{pageTitle}</h1>
            <h6 className="subtitle">Let's work together to create a digital strategy that has a winning combination of services, goods and capabilities.</h6>
        </div>
</section>

<section id="p_digital_solutions" className="section static-page page-content">
        <div className="container">
            <div className="columns">
                <div className="column is-12 content-area">
                    
                  <h3>The importance of having a digital strategy is increasingly growing, it is no longer optional, it is a key factor in today's market that can ensure that your business is profitable.</h3>
                  <span className="break"></span>
                  <p>Our experts will help your team, irrespective of their current phase, to build or update your plan. To serve your customers more digitally, whether it's digital marketing, new technologies or a transformative application.</p>
                  <span className="break"></span>
                  <h5>Digital Strategies </h5>
                  <p>Let us help you prepare and organize your digital course to improve your skills in this modern market.</p>
                  <h5>Digital Approach </h5>
                  <p>Our approach is tailored to fit your specific situation based on the expectations of our customers. With the following, our experts will help you to create a digital plan:</p>
                  <ul>
                    <li>Assessing multiple dimensions of your digital environment </li>
                    <li>Developing a comprehensive digital strategy and development plan</li>
                    <li>Analyzing the digital marketing ecosystem and technology infrastructure</li>
                  </ul>
                  <span className="break"></span>
                  <span className="break"></span>
                  <span className="break"></span>
                  <hr />
                  <h3>Let us help you succeed in the digital world of today</h3>
                  <p><a href="">Contact us</a></p>

                    
                </div>
            </div>
        </div>
      </section>



</Layout>
    )
  }
}

export default DigitalStrategy
